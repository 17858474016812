import React, { useState, useEffect } from 'react'
import { Hidden, Grid, Button, makeStyles,  AppBar, Toolbar, IconButton, Drawer, Typography, Grow } from '@material-ui/core';
import { useTheme, createStyles, Theme } from '@material-ui/core/styles';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useHistory, useLocation, useParams } from 'react-router'
import Logo from './Logo';
import { gaModalView } from '../../ga';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes slideRight': {
      from: { opacity: 0 },
      to: { opacity: 0.0 }
    },
    active: {
      position: "relative",
      color: "#64BE43",
      fontSize: "14px",
      paddingRight: "5px",
      textTransform: "uppercase",
      textDecoration: "none",
      animationName: '$slideRight',
      background: "none",
      padding: "30px 10px",
     
      fontWeight: 600,
      '&:after': {
        content: '""',
        position: "absolute",
        left: 0,
        right: "auto",
        animationName: '$slideRight',
        top: "26px",
        width: "50%",
        height: "1px",
        opacity: 1,
        backgroundColor: (props: {color: string}) => props.color,
        transition: "0.3s",
        boxSizing: "inherit"
      },
      '&:before': {
        content: '""',
        position: "absolute",
        right: 0,
        left: "auto",
        animationName: '$slideRight',
        bottom: "26px",
        width: "50%",
        height: "1px",
        opacity: 1,
        backgroundColor: (props: {color: string}) => props.color,
        transition: "0.3s",
        boxSizing: "inherit"
      }
    },
    item: {
      position: "relative",
      color: "#64BE43",
      fontSize: "14px",
      paddingRight: "5px",
      textTransform: "uppercase",
      background: "none",
      padding: "30px 10px",
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        padding: "0 0",
      }
    },
    root: {
      display: 'flex',
      position: 'fixed',
      zIndex: 1600,
      backgroundColor: "#64BE43",
      width: "100%",
      flexGrow: 1,
    },
    backGroundMenuOpen: {
      position:"absolute", 
      height: "100vh",
      width: "100vw", 
      zIndex: -5, 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0, 
      backgroundColor: "rgba(0,0,0,0.8)", 
      filter: "blur(1.2rem)"

    },
    appBar2: {
      flexGrow: 1, 
      height: "64px", 
      zIndex: 1300,
      background: "rgba(25,250,0,0.4)",
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
      "&:before": {
        content: "''",
        backgroundColor: "rgba(25,250,0,0.8)", 

      }
    },  
    appBar2Drawer: {
      flexGrow: 1, 
      zIndex: 1300,
      
     
    },  
    appBar: {
      [theme.breakpoints.up('sm')]: {

      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        minHeight: 128,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
   
    
  }),
);




  type HeaderItems = {
    color: string,
    handleClick?: () => void,
    indexState: [string, (index: string) => void]
  }


const HeaderItems = ({color, handleClick, indexState }: HeaderItems) => {
  const classes = useStyles({color})
  const [activeIndex, setActive] = indexState
  const history = useHistory()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const offset = isMobile ? -20: -50

  const handleClickDefault = (el: string) => {
    history.push(`/#${el}`)
    if(handleClick) handleClick()
  }

  const handleSetActive = (el: string) => {
    setActive(el)
  }


  return (
    <>
    <Grid item>
          </Grid>
          <Grid item>
            <Link  to="portfolio" isDynamic={true}  offset={offset} spy={true} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault('portfolio')} >
            <Button style={{ fontSize: "14px", color }} className={activeIndex === 'portfolio' ? classes.active : classes.item}>Elma-Isotank</Button>
            </Link>
          </Grid>
          <Grid item>
            <Link  to="testimonial" isDynamic={true} spy={true} offset={offset} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault('testimonial')}>
            <Button style={{ fontSize: "14px", color }} className={activeIndex === 'testimonial' ? classes.active : classes.item}>kundenbewertungen</Button>
            </Link>
          </Grid>
          <Grid item>
            <Link  to="price" spy={true} isDynamic={true} offset={offset} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault('price')} >
            <Button style={{ fontSize: "14px", color }} className={activeIndex === 'price' ? classes.active : classes.item}>Preise</Button>
            </Link>
          </Grid>
          <Grid item>
            <Link  to="about" spy={true} isDynamic={true} offset={offset} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault('about')}>
            <Button style={{ fontSize: "14px", color }} className={activeIndex === 'about' ? classes.active : classes.item}>Über uns</Button>
            </Link>
          </Grid>
          <Grid item>
            <Link  to="contact" isDynamic={true} spy={true} offset={offset} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault('contact')}>
            <Button style={{ fontSize: "14px", color }} className={activeIndex === 'contact' ? classes.active : classes.item}>Kontakt</Button>
            </Link>
          </Grid>
    </>
  )
}
export default HeaderItems