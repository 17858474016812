import React from 'react'
import { useHistory } from 'react-router'
import { scrollToTop } from '../../utils/scrollToTop'

const Logo = () => {

  const history = useHistory()
  const handleclick = () => {
    scrollToTop()
    history.push("/")
}
  return (
    <img onClick={handleclick} style={{ height: "64px", paddingTop: "8px" }} src="/images/logo/logo.png" alt="elma" />
  )
}

export default Logo