import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};


export const initPixels = () => {
  ReactPixel.init('920750788414280', undefined, options);
ReactPixel.pageView()
}