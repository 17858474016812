import React, { Component, useState } from 'react';
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { useCartContext } from '../../OnlineShopProvider';
import { ProductType } from '../ProductPage';

const QuickView = ({modalData, closeModal}: {modalData: ProductType, closeModal: () => void}) => {
  const [state, setState] = useState({
    qty: 1,
    max: 10,
    min: 1
  })
  const {state: {cart}, actions: {addToCart}} = useCartContext()
  const history = useHistory()


    const handleAddToCartFromView = () => {
        addToCart({
          ...modalData,
          quantity: state.qty
        })

        toast.success('Added to the cart', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });

        setTimeout(() => {closeModal()},5000); 
    }

    const IncrementItem = () => {
      setState(state => {return {...state, qty: ++state.qty}})
    }

    const DecreaseItem = () => {
      setState(state => {return {...state, qty: --state.qty}})
    }


        return (
            <div className="modal fade productQuickView show" style={{paddingRight: '16px', display: 'block'}}>
                <ToastContainer />
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="fas fa-times"></i></span>
                        </button>
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="productQuickView-image">
                                    <img src={modalData.image} alt="image" /> 
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="product-content">
                                    <h3>
                                        <a href="#">
                                            <a>{modalData.title}</a>
                                        </a>
                                    </h3>

                                    <div className="price">
                                        <span className="new-price">${modalData.price}</span>
                                    </div>


                                    <ul className="product-info">
                                        <li>
                                            <span>Availability:</span> <a href="#"><a>In stock (7 items)</a></a>
                                        </li>
                                       
                                    </ul>

                               

                                    <div className="product-add-to-cart">
                                        <div className="input-counter">
                                            <span 
                                                className="minus-btn"
                                                onClick={DecreaseItem}
                                            >
                                                <i className="fas fa-minus"></i>
                                            </span>
                                            <input 
                                                type="number" 
                                                value={state.qty}
                                                min={state.min}
                                                max={state.max} 
                                                onChange={e => setState(state => {return {...state,  qty: Number(e.target.value)}})}
                                            />
                                            <span 
                                                className="plus-btn"
                                                onClick={IncrementItem}
                                            >
                                                <i className="fas fa-plus"></i>
                                            </span>
                                        </div>

                                        <button 
                                            type="submit" 
                                            className="btn btn-primary"
                                            onClick={handleAddToCartFromView}
                                        >
                                            <i className="fas fa-cart-plus"></i> Add to Cart
                                        </button>
                                    </div>

                                    <a href="#" onClick={(e) => {
                                    e.preventDefault()
                                    history.push(`products/${modalData.id}`)
                                    }
                                    }>
                                        <a className="view-full-info">View full info</a>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}


export default (QuickView)
