import React, {  useState } from 'react';
import { toast } from 'react-toastify';
import Shipping from './Shipping';
import {ProductType} from '../ProductPage'
import { useCartContext } from '../../OnlineShopProvider';
import { Grid } from '@material-ui/core';

const ProductContent = ({product}: {product: ProductType}) => {
    const {state: {cart},actions: {addToCart, removeFromCart, setToCart}} = useCartContext()
    const [quantity, setQuantity] = useState(1)
    const [openShipDialog, setShipDialog] = useState(false)
    const [openSupport, setOpenSupport] = useState(false)

    const state = {
        qty: 1,
        max: 10,
        min: 1,
        sizeGuide: false,
        shipModal: false
    };

    const handleAddToCartFromView = () => {
        setToCart(
            {
                ...product,
                quantity
            }
        )
    }

    const IncrementItem = () => {
        setQuantity(e => ++e)
    }

    const DecreaseItem = () => {
        setQuantity(e => --e)
    }

  

        return (
            <React.Fragment>
                <div className="col-lg-6 col-md-6">
                    <div className="product-details-content">
                        <h3>{product.title}</h3>

                        <div className="price">
                            <span className="new-price">{product.price} €</span>
                        </div>


                        <p>{product.titleText}</p>

                        <ul className="product-info">
                            <li><span>Availability:</span> <a href="#"><a>In stock</a></a></li>
                        </ul>

                
                        <div style={{padding: "32px 0"}} className="product-info-btn">
                          
                            <a href="#">
                                <a
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setShipDialog(e => !e);
                                    }}
                                >
                                    <i className="fas fa-truck"></i> Shipping
                                </a>
                            </a>

                            <a href="#">
                                <a onClick={e => {
                                        e.preventDefault(); 
                                        setOpenSupport(e => !e);
                                    }}>
                                    <i className="far fa-envelope"></i> Ask about this product
                                </a>
                            </a>
                        </div>

                        <div className="product-add-to-cart">
                            <div className="input-counter">
                                <span 
                                    className="minus-btn"
                                    onClick={DecreaseItem}
                                >
                                    <i className="fas fa-minus"></i>
                                </span>
                                <input 
                                    type="number" 
                                    value={quantity}
                                    min={state.min}
                                    max={state.max} 
                                    onChange={e => setQuantity(Number(e.target.value) as number)}
                                />
                                <span 
                                    className="plus-btn"
                                    onClick={IncrementItem}
                                >
                                    <i className="fas fa-plus"></i>
                                </span>
                            </div>

                            <button 
                                type="submit" 
                                className="btn btn-primary"
                                onClick={handleAddToCartFromView}
                            >
                                <i className="fas fa-cart-plus"></i> Add to Cart
                            </button>
                        </div>


                        <div className="buy-checkbox-btn">
                            <div className="item">
                                <input className="inp-cbx" id="cbx" type="checkbox" />
                                <label className="cbx" htmlFor="cbx">
                                    <span>
                                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                    <span>I agree with the terms and conditions</span>
                                </label>
                            </div>

                            <div className="item">
                                <a href="#">
                                    <a className="btn btn-primary">Buy it now!</a>
                                </a>
                            </div>
                        </div>

                        <div className="custom-payment-options">
                            <span>Guaranteed safe checkout:</span>
                    <div className="payment-methods">
                            <Grid  container justify="center" spacing={1}>
                                <Grid item>
                                <img src={"/images/payment-image/1.svg"} alt="image" />
                                </Grid>
                                <Grid item>
                                <img src={"/images/payment-image/2.svg"} alt="image" />
                                </Grid>
                                <Grid item>
                                <img src={"/images/payment-image/3.svg"} alt="image" />
                                </Grid>
                                <Grid item>
                                <img src={"/images/payment-image/4.svg"} alt="image" />
                                </Grid>
                                <Grid item>
                                <img src={"/images/payment-image/5.svg"} alt="image" />
                                </Grid>
                                <Grid item>
                                <img src={"/images/payment-image/6.svg"} alt="image" />
                                </Grid>
                                <Grid item>
                                <img src={"/images/payment-image/7.svg"} alt="image" />
                                </Grid>          
                            </Grid>
                            </div>
                        </div>
                    </div>
                </div>
                { openShipDialog ? <Shipping closeShipModal={setShipDialog} /> : '' }
            </React.Fragment>
        );
}
export default ProductContent
/*
const mapDispatchToProps= (dispatch: any)=>{
    return {
        addQuantityWithNumber: (id: any, qty: any) => {dispatch(addQuantityWithNumber(id, qty))}
    }
}

export default connect(
    null,
    mapDispatchToProps
)(ProductContent)
*/