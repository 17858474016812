export const Premium = {
  title: "Elma-Isotank Premium",
  price: 1600,
  image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg'),
  imageHover: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg'),
  quickView: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg'),
  titleText: "",
  images: [
    {
        id: 1,
        image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
    },
    {
        id: 2,
        image: require('../elmaImages/price/Elma-Isotank-nur-Tank.jpg')
    },
    {
        id: 3,
        image: require('../elmaImages/price/elma-tank-offen.jpg')
    },
    {
        id: 4,
        image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
    },
    {
        id: 5,
        image: require('../elmaImages/price/Elma-Isotank-nur-Tank.jpg')
    },
    {
        id: 6,
        image: require('../elmaImages/price/elma-tank-offen.jpg')
    },
    {
        id: 7,
        image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
    }
]
}