import React from 'react';
import { useParams } from 'react-router';
import { getProducts } from '../utils/getProducts';
import DetailsTab from './components/DetailsTab';
import Facility from './components/Facility';
import ProductContent from './components/ProductContent';
import ProductImage from './components/ProductImage';


const Description = () => {
    
    return (
        <div id="tab1" className="tabs_item">
        <div className="products-details-tab-content">
            <p>Design inspiration lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi commodo, ipsum sed pharetra gravida, orci magna rhoncus neque, id pulvinar odio lorem non turpis. Nullam sit amet enim. Suspendisse id velit vitae ligula volutpat condimentum.  Aliquam erat volutpat. Sed quis velit. Nulla facilisi. Nulla libero. Vivamus pharetra posuere sapien. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nulla libero. Vivamus pharetra posuere sapien.</p>

            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <ul>
                        <li>Fabric 1: 100% Polyester</li>
                        <li>Fabric 2: 100% Polyester,Lining: 100% Polyester</li>
                        <li>Fabric 3: 75% Polyester, 20% Viscose, 5% Elastane</li>
                    </ul>
                </div>

                <div className="col-lg-6 col-md-6">
                    <ol>
                        <li>Fabric 1: 75% Polyester, 20% Viscose, 5% Elastane</li>
                        <li>Fabric 2: 100% Polyester,Lining: 100% Polyester</li>
                        <li>Fabric 3: 100% Polyester</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    )
}


export type ProductType = {
  id:number,
  image: string,
  imageHover: string,
  price: number,
  quickView: string,
  title: string,
  titleText: string,
  description?: React.ReactNode,
  images: { id: number, image: any}[ ] 
}


const Product = () => {
    const {id} = useParams()
    const productList = getProducts()
    const product: ProductType  = {
        ...productList.find(x => x.id === Number(id)) as ProductType,
        description: <Description/>
    }
    console.log(product)
    return (
        <React.Fragment>
            {/*<Navbar />
            <Breadcrumb title="Belted chino trousers polo" />*/}

            <section className="products-details-area pt-60">
                <div className="container">
                    <div className="row">
                        <ProductImage product={product} />
                        <ProductContent product={product} />
                        <DetailsTab product={product} />
                    </div>
                </div>
                <Facility />
            </section>

        </React.Fragment>
    );
}

export default Product