import { Fade, Grid } from '@material-ui/core';
import React,{ useState } from 'react';
import { useCartContext } from '../../../OnlineShopProvider';
import CartContact from '../CartContact';
import ShippingSwitch from './ShippingSwitch';


const CartContentPage = () =>  {
  const {state: {cart}, actions: {setShipping}} = useCartContext()
  const [isShipping, setIsShipping] = useState(false)

  
        return (
            <Grid container alignContent="center" alignItems="center" direction="column" justify="center">
                <Grid item >
                    <ShippingSwitch onSet={setIsShipping}/>
                </Grid>
                <Grid item style={{padding: "16px"}}/>
                <Grid item>
                <CartContact/>
                </Grid>

            </Grid>
                
        );
}


export default (CartContentPage)
