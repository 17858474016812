import React, { useEffect, useRef, useState } from 'react'
import { Hidden, Grid, Typography, makeStyles } from '@material-ui/core'
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { EventAction, EventCategory, sendEvent } from '../ga';



const useStyles = makeStyles((theme: Theme) =>
createStyles({
  instagram: {
   
    borderRadius: "4px",
    paddingLeft: "2px",
    paddingRight: "2px",
    color: "#fff",
    background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
   
  }
}))

const Instagram = ({height, location}: {height: number, location: string}) => {
  const classes = useStyles()

  const sendInstagram = () => {
    sendEvent(EventCategory.SOCIAL, EventAction.INSTAGRAM, location)
    window.open("https://www.instagram.com/elma_isotank/", '_blank')
  }


  return (
    <i onClick={sendInstagram} className={`${classes.instagram} fab fa-instagram`} style={{ fontSize: `${height}px`}}></i> 
  )

}

export default Instagram
