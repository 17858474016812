import React, { useState, useEffect } from 'react'
import { Hidden, Grid, Button, makeStyles,  AppBar, Toolbar, IconButton, Drawer, Typography, Grow } from '@material-ui/core';
import { useTheme, createStyles, Theme } from '@material-ui/core/styles';
import useWindowSize from '../../../utils/useWindowSize';
import { MobileHeader } from './MobileHeader';
import { useLocation } from 'react-router-dom'




const useHeader = makeStyles((theme: Theme) =>
  createStyles({
    header_mobile: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "transparent",
      color: "#64BE43",
      transition: "0.5s",
      display: "block",
      fontFamily: "'Open Sans', 'sans-serif'",
      width: "100vw",
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: 1.6,
      zIndex: 1000


    },


  }))


const MobileHeaderDefault = () => {
  const [width, height] = useWindowSize()
  const headerClasses = useHeader({ width })

  return (
    <>
      <Hidden lgUp>
        <div style={{ flexGrow: 1, }} className={headerClasses.header_mobile}>
          <MobileHeader />
        </div>
      </Hidden>
   
    </>
  )
}



export default MobileHeaderDefault