import React, { useEffect, useRef, useState } from 'react'
import { Hidden, Grid, Typography, makeStyles, IconButton } from '@material-ui/core'
import useWindowSize from '../../utils/useWindowSize'
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Instagram from '../Instagram';
import {sendEvent, EventCategory, EventAction} from '../../ga'
import { sendWhatsApp } from '../../utils/sendWhatsApp';
import { sendFaceBook } from '../../utils/sendFaceBook';
import { sendMailHref } from '../../utils/mails';


const useStyles = makeStyles((theme: Theme) =>
createStyles({
    hrefItem: {
    textDecoration: "none",
color: "black"
    },
    typography: {
        fill: "green",
        "&:hover": {
            color: "black",
            cursor: "pointer"
        }
    }
}))

const Header = () => {
    const [width,] = useWindowSize()
    const classes = useStyles()

    
  const handleWhatsAppClick = () => {
    const title = "Hallo Elma"
    const text ="Ich bin an den ELMA Isotank interessiert. Könnten Sie mir mehr Informationen zusenden?"
    sendEvent(EventCategory.CONTACT, EventAction.WHATSAPP, "Header")
    sendWhatsApp({title, text})

    
  }
  const handleFacebookClick = () => {
    const title = "Hallo Elma"
    const text ="Ich bin an den ELMA Isotank interessiert. Könnten Sie mir mehr Informationen zusenden?"
    sendEvent(EventCategory.CONTACT, EventAction.FACEBOOK, "Header")
    window.open("https://www.facebook.com/ELMA.Isotank", "_blank")
    //sendFaceBook({title, text})
  }

  const sendFaceBookMessenger = () => {
    sendEvent(EventCategory.SOCIAL, EventAction.FACEBOOK, "Header")
    window.open("https://www.messenger.com/t/ELMA.Isotank", "_blank")

  }


  const handleCall = () => {
    sendEvent(EventCategory.CONTACT, EventAction.CALL, "Header")
    window.open("tel:00496202924012", "_self")
  }

  const handleMail = () => {
    sendEvent(EventCategory.CONTACT, EventAction.EMAIL, "Header")
    sendMailHref({email: "info@elma-isotank.de", body: "Ich bin an den ELMA Isotank interessiert. Könnten Sie mir mehr Informationen zusenden?", subject: "Kontaktanfrage"})
  }


   
    return (
            <div  >
            <Hidden mdDown >
            <Grid  style={{ backgroundColor: "white",}}  container alignContent="center" alignItems="center"  >
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <Grid container wrap="nowrap" spacing={3}>
                        <Grid item>
                            <Grid  container onClick={handleCall} className={classes.typography}  spacing={1} alignContent="center"  alignItems="center">
                                    <Grid item>
                                    <CallIcon />
                                </Grid>
                                <Grid item>
                                    <Typography  variant="body1">+49 (0) 6202 924012</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container className={classes.typography}  onClick={handleMail} spacing={1} alignContent="center" alignItems="center">
                                <Grid item>
                                    <MailOutlineIcon />
                                </Grid>
                                <Grid item>
                                    <Typography  variant="body1"> info@elma-isotank.de</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={4}>
                    <Grid className="social-menu" style={{width: "100%"}} container spacing={2}>
                        <Grid item >
                           <i onClick={handleFacebookClick} className="fab fa-facebook" style={{color: "blue", fontSize: "24px"}}></i>
                        </Grid>
                        <Grid item>
                            <i onClick={sendFaceBookMessenger} className="fab fa-facebook-messenger" style={{color: "blue",fontSize: "24px"}}></i>
                        </Grid>
                        <Grid item >
                        <Instagram location={"Header"} height={24}/>
                        </Grid>
                        <Grid item>
                            <i onClick={handleWhatsAppClick} className="fab fa-whatsapp" style={{color: "green", fontSize: "24px"}}></i>
                         
                          
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Hidden>
            </div>
    )
}
export default Header