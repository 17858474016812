import { Grid } from '@material-ui/core';
import React from 'react';
import { ProductType } from '../ProductPage';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const DetailsTab = ({product}: {product: ProductType}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const openTabSection = (evt: any, tabNmae: any) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item") as HTMLCollectionOf<any> ;
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove("fadeInUp");
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae)!.style.display = "block";
        document.getElementById(tabNmae)!.className += " fadeInUp animated";
        evt.currentTarget.className += "current";
    }

        return (
          <>
          <Grid container style={{paddingTop: matches? "":"128px"}} wrap="nowrap"  className="tab products-details-tab">
            <Grid item xs={12}>
              <Grid container spacing={2} justify="center" className="tabs">
                <Grid item xs={12} md={3}>
          <li 
                              onClick={(e) => {e.preventDefault(); openTabSection(e, 'tab1')}}
                              className="current"
                          >
                              <span className="tabs-nav-text">
                                  <div className="dot"></div> Description
                              </span>
                          </li>
                </Grid>
                <Grid item xs={12} md={3}>
                <li onClick={(e) => {e.preventDefault(); openTabSection(e, 'tab3')}}>
                                    <span className="tabs-nav-text">
                                        <div className="dot"></div> Shipping
                                    </span>
                                </li>
                </Grid>
                <Grid item xs={12} md={3}>
                <li onClick={(e) => {e.preventDefault(); openTabSection(e, 'tab4')}}>
                                    <span className="tabs-nav-text">
                                        <div className="dot"></div> Why Buy From Us
                                    </span>
                                </li>
                </Grid>
                            
                            </Grid>
            </Grid>
          </Grid>
            <div style={{paddingTop: "128px"}} className="col-lg-12 col-md-12">
                <div className="tab products-details-tab">
                    <div className="row">
                        

                        <div className="col-lg-12 col-md-12">
                            <div className="tab_content">
                            {/*product?.description*/}

                                <div id="tab2" className="tabs_item">
                                    <div className="products-details-tab-content">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td>Color:</td>
                                                        <td>Blue, Purple, White</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Size:</td>
                                                        <td>20, 24</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Material:</td>
                                                        <td>100% Polyester</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Height:</td>
                                                        <td>180 cm - 5' 11”</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bust</td>
                                                        <td>83 cm - 32”</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Waist</td>
                                                        <td>57 cm - 22”</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hips</td>
                                                        <td>88 cm - 35</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shipping</td>
                                                        <td>Free</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div id="tab3" className="tabs_item">
                                    <div className="products-details-tab-content">
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>Shipping</td>
                                                        <td>This item Ship to USA</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Delivery</td>
                                                        <td>
                                                            Estimated between Wednesday 07/31/2020 and Monday 08/05/2020 <br />
                                                            Will usually ship within 1 bussiness day.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div id="tab4" className="tabs_item">
                                    <div className="products-details-tab-content">
                                        <p>Here are 5 more great reasons to buy from us:</p>

                                        <ol>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</li>
                                            <li> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</li>
                                            <li>when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>when an unknown printer took a galley of type and scrambled it to make a type specimen book.</li>
                                        </ol>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
                    );
   
}

export default DetailsTab;
