import React from 'react'
import { useMediaQuery } from '@material-ui/core'

const SettingsContext = React.createContext({
  state: { darkmode: 'dark', locale: 'en' },
  // tslint:disable-next-line
  actions: { setDarkmode: (darkmode: string) => { }, setLocale: (locale: string) => { } }
})

export const useSettingsContext = () => React.useContext(SettingsContext)

export const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [darkmode, setDarkmode] = React.useState(localStorage.getItem('darkmode') || prefersDarkMode ? 'dark' : 'light')
  const [locale, setLocale] = React.useState(localStorage.getItem('locale') || 'de')

  if (!localStorage.getItem('darkmode')) {
    localStorage.setItem('darkmode', prefersDarkMode ? 'dark' : 'light')
  }

  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'de')
  }

  return <SettingsContext.Provider value={{
    state: {
      darkmode, locale
    },
    actions: {
      setDarkmode: (newSetting: string) => { localStorage.setItem('darkmode', newSetting); setDarkmode(newSetting) },
      setLocale: (newSetting: string) => { localStorage.setItem('locale', newSetting); setLocale(newSetting) },
    }
  }}>
    {children}
  </SettingsContext.Provider>
}
