import { Button, Grid } from '@material-ui/core';
import React, { Component } from 'react';

class Banner extends Component {
    render() {
        return (
            <div style={{backgroundImage: "url(/images/slider/Elma-Isotank-pferd.jpg)"}} className="main-banner item-bg1">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div style={{backgroundColor: "rgba(255,255,255,0.5)"}} className="main-banner-content">
                                <span>Perfekt für Ihr Pferd</span>
                                <h1>Elma-Pferdetränke</h1>
                                <p>Frostsicher im Winter, Kühl & Frisch im Sommer</p>

                           
                                    <Grid container justify="center">
                                        <Grid item>
                                            <a className="btn btn-primary">
                                            Zu dne Tränken
                                            </a>
                                            <a className="btn btn-light">
                                           Zubehör
                                            </a>
                                        
                                        </Grid>
                                    </Grid>                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;
