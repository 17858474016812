import React, { useState, useEffect } from 'react'
import { Hidden, Grid, Button, makeStyles,  AppBar, Toolbar, IconButton, Drawer, Typography, Grow } from '@material-ui/core';
import { useTheme, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import useWindowSize from '../../utils/useWindowSize';
import Header from './Header';
import HideOnScroll from '../HideOnScroll';
import { useLocation } from 'react-router-dom'
import { useHistory, useParams } from 'react-router'
import Logo from './Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes slideRight': {
      from: { opacity: 0 },
      to: { opacity: 0.0 }
    },
    active: {
      position: "relative",
      color: "#64BE43",
      fontSize: "14px",
      paddingRight: "5px",
      textTransform: "uppercase",
      textDecoration: "none",
      animationName: '$slideRight',
      background: "none",
      padding: "30px 10px",
     
      fontWeight: 600,
      '&:after': {
        content: '""',
        position: "absolute",
        left: 0,
        right: "auto",
        animationName: '$slideRight',
        top: "26px",
        width: "50%",
        height: "1px",
        opacity: 1,
        backgroundColor: (props: {color: string}) => props.color,
        transition: "0.3s",
        boxSizing: "inherit"
      },
      '&:before': {
        content: '""',
        position: "absolute",
        right: 0,
        left: "auto",
        animationName: '$slideRight',
        bottom: "26px",
        width: "50%",
        height: "1px",
        opacity: 1,
        backgroundColor: (props: {color: string}) => props.color,
        transition: "0.3s",
        boxSizing: "inherit"
      }
    },
    item: {
      position: "relative",
      color: "#64BE43",
      fontSize: "14px",
      paddingRight: "5px",
      textTransform: "uppercase",
      textDecoration: "none",
      background: "none",
      padding: "30px 10px",
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        padding: "0 0",
      }
    },
    root: {
      display: 'flex',
      position: 'fixed',
      zIndex: 1600,
      backgroundColor: "#64BE43",
      width: "100%",
      flexGrow: 1,
    },
    backGroundMenuOpen: {
      position:"absolute", 
      height: "100vh",
      width: "100vw", 
      zIndex: -5, 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0, 
      backgroundColor: "rgba(0,0,0,0.8)", 
      filter: "blur(1.2rem)"

    },
    appBar2: {
      flexGrow: 1, 
      height: "64px", 
      zIndex: 1300,
      background: "rgba(25,250,0,0.4)",
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
      "&:before": {
        content: "''",
        backgroundColor: "rgba(25,250,0,0.8)", 

      }
    },  
    appBar2Drawer: {
      flexGrow: 1, 
      zIndex: 1300,
      
     
    },  
    appBar: {
      [theme.breakpoints.up('sm')]: {

      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        minHeight: 128,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
   
    
  }),
);


const useHeader = makeStyles((theme: Theme) =>
  createStyles({
    header_lg: {
      position: "relative",
      top: 0,
      left: 0,
      backgroundColor: "#64BE43",
      transition: "0.5s",
      fontFamily: "'Open Sans', 'sans-serif'",
      fontWeight: 300,
      fontSize: "15px",
      color: "#fff",
      lineHeight: 1.6,
      zIndex: 1000

    },
    header_lg_fixed: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "#fff",
      color: "#64BE43",
      transition: "0.5s",
      display: "block",
      fontFamily: "'Open Sans', 'sans-serif'",
      width: (props: { width: number }) => `${props.width}px`,
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: 1.6,
      zIndex: 1000


    },
    header_mobile: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "transparent",
      color: "#64BE43",
      transition: "0.5s",
      display: "block",
      fontFamily: "'Open Sans', 'sans-serif'",
      width: (props: { width: number }) => `${props.width}px`,
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: 1.6,
      zIndex: 1000


    },


  }))

  const setLocation = (location: string) => {
    switch (location) {
      case "faq":
       return 0;
       case "service":
        return 1;
        break;
      default:
        return -1;
    }
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  type HeaderItems = {
    color: string,
    handleClick?: () => void,
    indexState: [string, (index: string) => void]
  }


const AdditionalItems = ({color, handleClick, indexState}: HeaderItems) => {
  const classes = useStyles({color})
  const [activeIndex, setActive] = indexState
  const history = useHistory()
 

  const handleClickDefault = (path: string, el: string) => {
    setActive(el)
    scrollToTop()
    history.push(`${path}`)
    if(handleClick) handleClick()
  }

  
  const handleSetActive = (el: string) => {
    setActive(el)
  }

 


  return (
    <>
    <Grid item>
       <Link  to="products"  offset={0} spy={true} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault("/products", "products")}  >
            <Button  style={{ fontSize: "14px", color }} className={activeIndex === 'products' ? classes.active : classes.item}>produkte</Button>
            </Link>
          </Grid>
       <Grid item>
       <Link  to="zubehör"  offset={0} spy={true} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault("/supplements", "supplements")}  >
            <Button  style={{ fontSize: "14px", color }} className={activeIndex === 'supplements' ? classes.active : classes.item}>zubehör</Button>
            </Link>
          </Grid>
          <Grid item>
          <Link  to="info"  offset={0} spy={true} smooth={true} duration={250} onSetActive={handleSetActive} onClick={() => handleClickDefault("/info", "info")} >
            <Button  style={{ fontSize: "14px", color }} className={activeIndex === 'info' ? classes.active : classes.item}>Mehr über den Isotank</Button>
            </Link>
          </Grid>
    <Grid item>
    <Link  to="faq"  offset={0} spy={true} smooth={true} duration={250} onSetActive={handleSetActive}  onClick={() => handleClickDefault("/faq", "faq")} >
    <Button style={{ fontSize: "14px", color }} className={activeIndex === 'faq' ? classes.active : classes.item}>faq</Button>
            </Link>
      
            
          </Grid>
          <Grid item>
          <Link to="service"  offset={0} spy={true} smooth={true} duration={250} onSetActive={handleSetActive}  onClick={() => handleClickDefault("/service", "service")} >
          <Button  style={{ fontSize: "14px", color }} className={activeIndex === 'service' ? classes.active : classes.item}>service</Button>
            </Link>
             
          </Grid>
       
          
    </>
  )
}
export default AdditionalItems