import React, { Component } from 'react';
import CartProducts from './CartProducts';
import { useCartContext } from '../../OnlineShopProvider';
import CartContentShipping from './Shipping/CartContentShipping';

const CartContentPage = () =>  {
  const {state: {cart}, actions: {setShipping}} = useCartContext()

  

    const handleChecked = (e: any) => {
        if(e.target.checked){
            setShipping(Number(6))
        } else {
            setShipping(0)
        }
    }

        return (
            <section className="cart-area ptb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <form>
                                <div className="cart-table table-responsive">
                                    <CartProducts />
                                </div>

                                <div className="cart-buttons">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="continue-shopping-box">
                                                <a href="/">
                                                    <p className="btn btn-light">Continue Shopping</p>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div>
                                    <CartContentShipping/>
                                </div>

                                <div className="cart-totals">
                                    <h3>Cart Totals</h3>

                                    <ul>
                                        <li>Subtotal <span>${cart.amount}</span></li>
                                        <li>Shipping <span>${cart.shipping}</span></li>
                                        <li>Total <span><b>${cart.total}</b></span></li>
                                    </ul>
                                    <a href="/checkout">
                                        <p className="btn btn-light">Proceed to Checkout</p>
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
}


export default (CartContentPage)
