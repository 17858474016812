import React, { Component, useState } from 'react';
import { toast } from 'react-toastify';
import { useCartContext } from '../../OnlineShopProvider';
import CartProduct from './CartProduct';

const CartProducts = () => {
  const {state: {cart}, actions: {removeFromCart}} = useCartContext() 

        let cartItems = cart.listItems.length ?
        (
            cart.listItems.map((data, idx) => {
                return (
                    <CartProduct product={data} idx={idx}/>
                )
            })
        ): (
            <tr>
                <td className="product-thumbnail" colSpan={5}>
                    <p>Empty.</p>
                </td>
            </tr>
        );

        return (
            <>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems}
                    </tbody>
                </table>
            </>
        );
}

export default (CartProducts)
