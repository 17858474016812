import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { usePath, usePathRouter } from '../../utils/usePathRouter';
import { ProductType } from '../ProductPage';
import AddToCart from './AddToCart';
import QuickView from './QuickView';

const Products = ({products}: {products: ProductType[]}) =>  {
    const [state, setState] = useState({
        modalOpen: false,
        modalData: null
    })
    const history = useHistory()
   


    const openModal = () => {
        setState(state => {return {...state, modalOpen: true }});
    }

    const closeModal = () => {
        setState(state => {return {...state, modalOpen: false }});
    }

    const handleModalData = (data: any) => {
        setState(state => {return {
            ...state,
            modalData: data
        }});
    }

    
        return (
            <section className="all-products-area pb-60">
                <div className="container">
                    <div className="tab products-category-tab">
                        <div className="row">

                            <div className="col-lg-12 col-md-12">
                                <div className="tab_content">
                                    <div id="tab1" className="tabs_item">
                                        <div className="row">
                                            {products.map((data, idx) => (
                                                <div className="col-lg-3 col-sm-6 col-6" key={idx}>
                                                    <div className="single-product-box">
                                                        <div className="product-image">
                                                            <a onClick={(e) => {
                                                                e.preventDefault()
                                                                history.push(`products/${data.id}`)
                                                            }} href="/" //as={`/product/${data.id}`}
                                                            >
                                                                <a>
                                                                    <img src={data.image} alt="image" />
                                                                    <img src={data.imageHover} alt="image" />
                                                                </a>
                                                            </a>

                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <a 
                                                                            data-tip="Quick View" 
                                                                            data-place="left" 
                                                                            onClick={e => {
                                                                                    e.preventDefault(); 
                                                                                    openModal();
                                                                                    handleModalData(data)
                                                                                }
                                                                            }
                                                                        >
                                                                            <i className="far fa-eye"></i>
                                                                        </a>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                               
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="product-content">
                                                            <h3>
                                                                <a onClick={(e) => {
                                                                e.preventDefault()
                                                                history.push(`products/${data.id}`)
                                                            }} href="/" //as={`/product/${data.id}`}
                                                                >
                                                                    <a style={{height: "32px", overflow: "hidden"}}> {data.title}</a>
                                                                </a>
                                                            </h3>

                                                            <div className="product-price">
                                                                <span className="new-price">{data.price},- €</span>
                                                            </div>

                                                        
                                                            
                                                            <AddToCart product={data} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { state.modalOpen ? <QuickView 
                    closeModal={closeModal} 
                    modalData={state.modalData!}
                /> : ''}
            </section>
        );

}

export default Products
