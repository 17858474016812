import ReactGA from 'react-ga';

const trackingId = "UA-175236389-1"; // Replace with your Google Analytics tracking ID

const trackingId2 = "246131559"; // Replace with your Google Analytics tracking ID


const trackers: ReactGA.Tracker[] =  [
  {
    trackingId:trackingId,
    gaOptions: {
      name: "official"
    }
  },
  {
    trackingId:trackingId2,
    gaOptions: {
      name: "test"
    }
  }
]

export const initAnaltytics = () => {
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash);
ReactGA.initialize(trackers, {debug: true})
  ReactGA.addTrackers(trackers)

}

export const disable = () => {
  const newWindow = window as any
  newWindow['ga-disable-'+trackingId] = true;
  newWindow['ga-disable-'+trackingId2] = true;
}

export enum EventAction  {
  EMAIL = "email",
  WHATSAPP = "whatsapp",
  FACEBOOK ="facebook",
  INSTAGRAM = "instagram",
  PURCHASE ="purchase",
  ORDER = "order",
  CALL ="call",
  INFO ="info",
 
} 

export enum EventCategory {
  CONTACT ="contact",
  BUTTONCLICK ="button-click",
  ORDER = "order",
  SOCIAL = "social",
  INTERESSE = "interesse",
  FORMULAR ="Kontaktformular"

}


export enum ModalView {
  CONTACT = "contact",
  PRICE = "price",
  ABOUT ="about",
  TESTIMONIALS ="testimonials"
}
export enum Transport {
  BEACON = 'beacon',
  XHR =  'xhr',
  IMAGE =  'image' 
}

export const sendEvent = (category: EventCategory, action: EventAction, label: string, nonInteraction = false, transport:  Transport = Transport.BEACON) => {
  
  
  ReactGA.event({category, action, label, nonInteraction, transport})
}

export const gaModalView = (activeIndex: string) => {
  ReactGA.modalview(activeIndex);
}