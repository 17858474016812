import React, { useState, useEffect, useRef } from 'react'
import { Hidden, Grid, Button, makeStyles, AppBar, Toolbar, IconButton, Drawer, Typography, Grow } from '@material-ui/core';
import { useTheme, createStyles, Theme } from '@material-ui/core/styles';
import useWindowSize from '../../../utils/useWindowSize';
import Header from '../Header';
import HideOnScroll from '../../HideOnScroll';
import HeaderLarge from './HeaderLarge';

const useHeader = makeStyles((theme: Theme) =>
  createStyles({
    header_lg: {
      position: "relative",
      top: 0,
      left: 0,
      backgroundColor: "#64BE43",
      transition: "0.5s",
      fontFamily: "'Open Sans', 'sans-serif'",
      fontWeight: 300,
      fontSize: "15px",
      color: "#fff",
      lineHeight: 1.6,
      zIndex: 1000

    },
    header_lg_fixed_white: {
      top: 0,
      left: 0,
      backgroundColor: "#fff",
      color: "#64BE43",
      transition: "0.5s",
      display: "block",
      fontFamily: "'Open Sans', 'sans-serif'",
      width: "100vw",
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: 1.6,
      zIndex: 1000


    },
    header_lg_fixed_green: {
      top: 0,
      left: 0,
      backgroundColor: "#64BE43",
      color: "#fff",
      transition: "0.5s",
      display: "block",
      fontFamily: "'Open Sans', 'sans-serif'",
      width: "100vw",
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: 1.6,
      zIndex: 1000
    },

  }))


const LargeHeaderDefault = () => {
  const [width, height] = useWindowSize()
  const headerClasses = useHeader({ width })




  return (
    <Hidden mdDown >
      <HideOnScroll onHidden={() => ''} >
        <AppBar className={headerClasses.header_lg_fixed_white} style={{ flexGrow: 1, zIndex: 1300 }} position="fixed">
          <Header />
          <Toolbar>
            < HeaderLarge color={"#64BE43"} />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Hidden>

  )
}


export default LargeHeaderDefault