import React, { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useCartContext } from '../../OnlineShopProvider';
import CartContent from './CartContent';


const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }),
)(Badge);

 const CartItem = ({size}: {size?: string}) => {
   const {state: {cart},
  actions: {getItemSize}} = useCartContext()
  const [open, setOpen] = useState(false)



  return(
    <>
    <IconButton aria-label="cart" onClick={() => setOpen(open => !open)}>
    <StyledBadge badgeContent={getItemSize()} color="secondary">
      <ShoppingCartIcon style={{fontSize: size || "32px"}} />
    </StyledBadge>
  </IconButton>
  <CartContent display={open} setDisplay={(show: boolean) => setOpen(show)}/>
  </>
  )
 }

 export default CartItem