import React  from 'react';
import { ProductCart, useCartContext } from '../../OnlineShopProvider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const CartContent = ({display, setDisplay}: {display: boolean,setDisplay: (show: boolean) => void}) => {
    const {state: {cart: {amount, listItems}},} = useCartContext()
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    
    const closeCart = () => {
        setDisplay(false)
    }

    if(display){
      return (
          <div style={{
            display: "block", paddingRight: "16px",
            width: isDesktop? "100%" : "100vw !important",
            height: isDesktop? "100%" : "100vh"
        }}>
        <div 
            className={isDesktop ? "modal right fade show shoppingCartModal": "modal full fade show shoppingCartModal"} 
            style={{
                display: "block",
                width: isDesktop? "100%" : "100vw !important",
                height: isDesktop? "100%" : "100vh",
                paddingRight: isDesktop?   "16px":"0px",
              
                
            }}
        >
            <div className="modal-dialog" role="document"
            style={{
                height: isDesktop? "100%" : "100vh",
                width: isDesktop? "100%" : "100vw !important",
                margin:  0
            }}
            >
                <div className="modal-content"
                style={{
                    width: isDesktop? "100%" : "100vw !important",
                    height: isDesktop? "100%" : "100vh",
                }}>
                    <button 
                        type="button" 
                        className="close" 
                        data-dismiss="modal" 
                        aria-label="Close"
                        onClick={closeCart}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <div className="modal-body">
                        <h3>My Cart ({listItems.length})</h3>

                        <div className="product-cart-content">

                            {listItems.length > 0 ? listItems.map((product: ProductCart, idx: number) => (
                                <div className="product-cart" key={idx}>
                                    <div className="product-image">
                                        <img src={product.image} alt="image" />
                                    </div>

                                    <div className="product-content">
                                        <h3>
                                            <a href="#">
                                                <a>{product.title}</a>
                                            </a>
                                        </h3>
                                        <span>Blue / XS</span>
                                        <div className="product-price">
                                            <span>{product.quantity}</span>
                                            <span>x</span>
                                            <span className="price">${product.price}</span>
                                        </div>
                                    </div>
                                </div>
                            )) : 'Empty'}
                            
                        </div>

                        <div className="product-cart-subtotal">
                            <span>Subtotal</span>

                            <span className="subtotal">${amount}</span>
                        </div>

                        <div className="product-cart-btn">
                            <a href="/checkout">
                                <a className="btn btn-primary">Proceed to Checkout</a>
                            </a>
                            <a href="/cart">
                                <a className="btn btn-light">View Shopping Cart</a>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
    }
    else{
      return null
    }

}



export default CartContent