import React from 'react'
import { useCartContext } from '../../OnlineShopProvider'
import { ProductType } from '../ProductPage'

const AddToCart = ({product}: {product: ProductType}) => {
  const {state: {cart}, actions: {addToCart}} = useCartContext()

    const handleAddToCart = (product: ProductType) => {
      addToCart({
        ...product,
        quantity: 1
      })

    }

    return(
        <a href="#">
            <p
                className="btn btn-light"
                onClick={(e) => {
                    e.preventDefault(); 
                    handleAddToCart(product)
                }}
            >
                Add to Cart
            </p>
        </a>
    )
}

export default AddToCart;