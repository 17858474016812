import { ContactDataType } from "../Contact/contactUtils"

const URL = "https://wa.me/496202924012?text="

export type ContactType = {
  title: string,
  text: string,
  data?: ContactDataType
}

const createLine = (data: string | undefined, title: string) => {
  if (data) return title + data + "\n \n"
  else return ""
}

export const sendWhatsApp = ({title, text, data}: ContactType ) => {

  let whatsAppText = title+ "\n"
  if(data && data.subject)  whatsAppText += "Subjekt: "+ data.subject + "\n"
  if(data && data.email)  whatsAppText +=  "Email: "+ data.email + "\n \n"
  if(data && data.postal) whatsAppText+= createLine(data.postal, "PLZ: ")
  if(data && data.country)  whatsAppText+= createLine(data.country, "Land: ")
   
  whatsAppText += text + "\n \n" + "Liebe Grüße, "
  if(data && data.name) whatsAppText += data.name

 
  let url = URL+encodeURI(whatsAppText)

  window.open(url, "_blank")
}
