import React, { useState, useEffect } from 'react'
import { Hidden, Grid, Button, makeStyles, AppBar, Toolbar, IconButton, Drawer, Typography, Grow } from '@material-ui/core';
import { useTheme, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import useWindowSize from '../../../utils/useWindowSize';
import { useLocation } from 'react-router-dom'
import Logo from '../Logo';
import HeaderItems from '../HeaderItems';
import AdditionallItems from '../AdditionallItems'
import { gaModalView } from '../../../ga';
import CartItem from '../../Cart/CartItem';



export const getDefaultLocation = (location: any) => {
  if (location.hash !== '') {
    return location.hash.substring(1)
  }
  else {
    return location.pathname.split("/")[1]
  }


}


const HeaderLarge = ({ color }: { color: string }) => {
  const [width, height] = useWindowSize()
  const location = useLocation()
  const [activeIndex, setActiveIndex] = useState(getDefaultLocation(location))

  useEffect(() => {
    gaModalView(activeIndex)
  }, [activeIndex])


  return (
    <Grid container wrap="nowrap" style={{ paddingBottom: "16px", flexGrow: 1, height: "84px" }} alignContent="center" alignItems="center" >
      <Grid item style={{ marginLeft: "8px", overflow: "hidden", marginRight: "16px" }} >
        <Logo />
      </Grid>
      <Grid item >
        <Grid container wrap="nowrap" justify="center" alignContent="center">
          <Grid item >
            <Grid container style={{ marginRight: "64px" }} >
              <HeaderItems indexState={[activeIndex, setActiveIndex]} color={color} />
            </Grid>
          </Grid>
          <Grid item />
          <Grid item >
            <Grid container wrap="nowrap" style={{}} >
              <AdditionallItems indexState={[activeIndex, setActiveIndex]} color={color} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{padding: "0 20"}}>
        <CartItem/>
        </Grid>
    </Grid>

  )
}

export default HeaderLarge