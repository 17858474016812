export const Basic = {
  title: "Elma-Isotank Basic",
  price: 1350,
  image: require("../images/img1.jpg"),
  imageHover: require("../images/img-hover1.jpg"),
  quickView: ("../images/quick-view-img.jpg"),
  titleText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
  images: [
          {
              id: 1,
              image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
          },
          {
              id: 2,
              image: require('../elmaImages/price/Elma-Isotank-nur-Tank.jpg')
          },
          {
              id: 3,
              image: require('../elmaImages/price/elma-tank-offen.jpg')
          },
          {
              id: 4,
              image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
          },
          {
              id: 5,
              image: require('../elmaImages/price/Elma-Isotank-nur-Tank.jpg')
          },
          {
              id: 6,
              image: require('../elmaImages/price/elma-tank-offen.jpg')
          },
          {
              id: 7,
              image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
          }
  ]
}