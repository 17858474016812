import React, { useState, useEffect } from 'react'
import { Hidden, Grid, Button, makeStyles,  AppBar, Toolbar, IconButton, Drawer, Typography, Grow } from '@material-ui/core';
import { useTheme, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import useWindowSize from '../../../utils/useWindowSize';
import Header from '../Header';
import HideOnScroll from '../../HideOnScroll';
import { useLocation } from 'react-router-dom'
import Logo from '../Logo';
import HeaderItems from '../HeaderItems';
import AdditionalItems from '../AdditionallItems';
import { gaModalView } from '../../../ga';
import { getDefaultLocation } from '../LargeHeader/HeaderLarge';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes slideRight': {
      from: { opacity: 0 },
      to: { opacity: 0.0 }
    },
    active: {
      position: "relative",
      color: "#64BE43",
      fontSize: "14px",
      paddingRight: "5px",
      textTransform: "uppercase",
      textDecoration: "none",
      animationName: '$slideRight',
      background: "none",
      padding: "30px 10px",
     
      fontWeight: 600,
      '&:after': {
        content: '""',
        position: "absolute",
        left: 0,
        right: "auto",
        animationName: '$slideRight',
        top: "26px",
        width: "50%",
        height: "1px",
        opacity: 1,
        backgroundColor: (props: {color: string}) => props.color,
        transition: "0.3s",
        boxSizing: "inherit"
      },
      '&:before': {
        content: '""',
        position: "absolute",
        right: 0,
        left: "auto",
        animationName: '$slideRight',
        bottom: "26px",
        width: "50%",
        height: "1px",
        opacity: 1,
        backgroundColor: (props: {color: string}) => props.color,
        transition: "0.3s",
        boxSizing: "inherit"
      }
    },
    item: {
      position: "relative",
      color: "#64BE43",
      fontSize: "14px",
      paddingRight: "5px",
      textTransform: "uppercase",
      textDecoration: "none",
      background: "none",
      padding: "30px 10px",
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        padding: "0 0",
      }
    },
    root: {
      display: 'flex',
      position: 'fixed',
      zIndex: 1600,
      backgroundColor: "#64BE43",
      width: "100%",
      flexGrow: 1,
    },
    backGroundMenuOpen: {
      position:"absolute", 
      height: "100vh",
      width: "100vw", 
      zIndex: -5, 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0, 
      backgroundColor: "rgba(0,0,0,0.8)", 
      filter: "blur(1.2rem)"

    },
    appBar2: {
      flexGrow: 1, 
      height: "64px", 
      zIndex: 1300,
      background: "rgba(25,250,0,0.4)",
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
      "&:before": {
        content: "''",
        backgroundColor: "rgba(25,250,0,0.8)", 

      }
    },  
    appBar2Drawer: {
      flexGrow: 1, 
      zIndex: 1300,
      position: "fixed",
      top: 84,
      left: 0,
      right: 0,
      bottom: 84,
      overflow: "scroll"
      
     
    },  
    appBar: {
      [theme.breakpoints.up('sm')]: {

      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        minHeight: 128,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
   
    
  }),
);



  type DrawerType = {
    handleClick: () => void,
    mobileOpen: boolean
  }
  const Drawerv2 = ({mobileOpen, handleClick}: DrawerType) => {
    const classes = useStyles();
    const location = useLocation()
    const [activeIndex, setActiveIndex] = useState(getDefaultLocation(location))

    useEffect(() => {
      gaModalView(activeIndex)
    }, [activeIndex])

    return (
      <Grow  in={mobileOpen}  unmountOnExit>
      <Grid container wrap="nowrap" className={classes.appBar2Drawer} alignItems="center" direction="column">
        <Grid item style={{ width: "80vw" }}>
          <Divider style={{color: "white", backgroundColor: "black"}}  />
        </Grid>
        <Grid item style={{ paddingTop: "32px", paddingBottom: "32px" }}>
          <Grid container spacing={2} justify="center" alignItems="center" alignContent="center" direction="column">
            <HeaderItems indexState={[activeIndex, setActiveIndex]} color={"white"} handleClick={handleClick}/>
          </Grid>
        </Grid>
        <Grid item style={{ width: "80vw" }}>
          <Divider style={{color: "white", backgroundColor: "black"}} />
        </Grid>
        <Grid item style={{ paddingTop: "32px", paddingBottom: "32px" }}>
        <Grid container spacing={2} justify="center" alignItems="center" alignContent="center" direction="column">
        <AdditionalItems indexState={[activeIndex, setActiveIndex]} color={"white"} handleClick={handleClick}/>
        </Grid>
        </Grid>
      </Grid>
      </Grow>
    );
  }
  export const  MobileHeader = () => {
    const [width, height] = useWindowSize()
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
   

  
    const handleDrawerToggle = (event: any) => {
      event.preventDefault()
      setMobileOpen(!mobileOpen);
    };
  
    const handleClick = () => {
      setMobileOpen(false)
    }
  
    const onHidden = (isHidden: boolean) => {
      if(isHidden) setMobileOpen(false) 
    }
    
  
    return (
      <>
      <HideOnScroll onHidden={onHidden} >
      <AppBar className={classes.appBar2} style={{flexGrow: 1, height: "64px", zIndex: 1300}} position="static">
    <Toolbar>
      <Grid container>
        <Grid item xs={12}>
        <Logo />
        </Grid>
      </Grid>
      <IconButton  
              id="iconbutton"
              style={{position: "absolute", top:0, right: 0}}
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={classes.menuButton} edge="end"  color="inherit" >
        <MenuIcon style={{fontSize :"32px"}} />
      </IconButton>
    </Toolbar>
  
  </AppBar>
  </HideOnScroll>
  <div>
    <Drawerv2 mobileOpen={mobileOpen} handleClick={handleClick}/>
  </div> 
      {mobileOpen && <div onClick={handleClick} className={classes.backGroundMenuOpen} />}
      </>
  
    );
  }