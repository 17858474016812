import React, { useEffect } from 'react'
import {ProductType} from './E-CommerceSite/ProductPage'

export type ProductCart = ProductType & {
  quantity: number
}

type CartType = {
  amount: number,
  shipping: number,
  total: number
  listItems: ProductCart[],
}

type Context = {
  state: {
    cart:CartType
  },
  actions: {
    addToCart: (listItem: ProductCart) => void,
    setToCart: (listItem: ProductCart) => void,
    removeFromCart: (listItem: ProductCart) => void,
    getItemSize: () => number,
    setShipping: (shipping: number) => void
  }
 
}

const parseListItem = (listItem: ProductCart) => {
  const newListItem = {...listItem}
  delete newListItem.description
  return newListItem
}

const OnlineShopContext = React.createContext<Context>({
  state: { 
    cart: {
      amount: 0,
      shipping:0,
      total: 0,
      listItems: []
    }, 
   },
  // tslint:disable-next-line
  actions: {
  addToCart: (listItem: ProductCart) => { },
  setToCart: (listItem: ProductCart) => {}, 
  removeFromCart: (listItem: ProductCart) => { },
  getItemSize: () => {return 0},
  setShipping: (shipping: number) => {}}
})

export const useCartContext = () => React.useContext(OnlineShopContext)

export const OnlineShopProvider = ({ children }: { children: React.ReactNode }) => {
  let localStorageData: CartType | null = null
  if(localStorage.getItem("CartContext")){
    localStorageData  = JSON.parse(localStorage.getItem("CartContext")!) as CartType
  }
  const [amount, setAmount] = React.useState<number>(localStorageData? localStorageData.amount : 0)
  const [listItems, setListitems] = React.useState<ProductCart[]>(localStorageData ? localStorageData.listItems : [])
  const [shipping,setShipping] = React.useState(localStorageData ? localStorageData.shipping : 0)
  const [total,setTotal] = React.useState(localStorageData ? localStorageData.total : 0)

  useEffect(() => {
    let newAmount = 0
    for(let item of listItems){
      newAmount += item.price*item.quantity
    }
    setTotal(newAmount+shipping)
    setAmount(newAmount)
  },[amount, shipping, listItems])

 
  if(!localStorage.getItem("CartContext")){
    localStorage.setItem("CartContext", JSON.stringify({amount, shipping, listItems})) 
  } 

  return <OnlineShopContext.Provider value={{
    state: {
      cart:{
        amount,
        shipping,
        listItems,
        total
      }
    },
    actions: {
      setShipping: (shipping: number) => {
        setShipping(shipping)
      },
      addToCart: (listItem: ProductCart) => {
        const newListItems = listItems
        const parsedListItem = parseListItem(listItem)
        const index = newListItems.findIndex(x => x.id === parsedListItem.id)
        if(index != -1){
          newListItems[index].quantity += 1
        }
        else{
          newListItems.push(parsedListItem)
        }
        setListitems(newListItems)
        localStorage.setItem("CartContext", JSON.stringify({amount, shipping, listItems:newListItems})) 
      },
      setToCart: (listItem: ProductCart) => {
        const parsedListItem = parseListItem(listItem)
        let newListItems = [...listItems]
        const index = newListItems.findIndex(x => x.id === parsedListItem.id)
        if(parsedListItem.quantity === 0){
          newListItems = newListItems.filter(x => x.id != parsedListItem.id)
        }
        else  if(index != -1){
          newListItems[index]= parsedListItem
        }
        else{
          newListItems.push(parsedListItem)
        }
        setListitems(newListItems)
        localStorage.setItem("CartContext", JSON.stringify({amount, shipping, listItems:newListItems})) 
      },
      removeFromCart: (listItem: ProductCart) => {
        let newListItems = listItems
        const index = newListItems.findIndex(x => x.id === listItem.id)
        if(listItem.quantity === 0){
          newListItems = newListItems.filter(x => x.id != listItem.id)
        }
        else if(index != -1){
          newListItems[index].quantity -= 1
        }
        setListitems(newListItems)
        localStorage.setItem("CartContext", JSON.stringify({amount, shipping, listItems:newListItems})) 
      },
      getItemSize: () => {
        const reducer = (accumulator: number, currentValue: ProductCart) => accumulator + currentValue.quantity;
        return listItems? listItems.reduce(reducer, 0): 0
      } 
    }
  }}>
    {children}
  </OnlineShopContext.Provider>
}
