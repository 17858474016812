import React, {  useState } from 'react';
import { toast } from 'react-toastify';
import { ProductCart, useCartContext } from '../../OnlineShopProvider';

const CartProduct = ({product, idx}: {product: ProductCart, idx: number}) => {
  const {state: {cart}, actions: {setToCart}} = useCartContext() 
  const [quantity, setQuantity] = useState(product.quantity)
  console.log(cart.listItems)

    const handleRemove = (id: any) => {
        setToCart({
            ...product,
            quantity: 0
        })

    }
    //to add the quantity
   const  handleAddQuantity = (id: any)=>{
    setToCart({
        ...product,
        quantity: quantity+1
    })
    setQuantity(qua => ++qua)

        //this.props.addQuantity(id);
    }
    //to substruct from the quantity
   const  handleSubtractQuantity = (id: any)=>{
    
    setToCart({
        ...product,
        quantity: quantity-1
    })
    setQuantity(qua => --qua)
        //this.props.subtractQuantity(id);
    }

  
                return (
                    <tr key={idx}>
                        <td className="product-thumbnail">
                            <a href="#">
                                <a>
                                    <img src={product.image} alt="item" />
                                </a>
                            </a>
                        </td>

                        <td className="product-name">
                            <a href="#">
                                <a>{product.title}</a>
                            </a>
                            <ul>
                            </ul>
                        </td>

                        <td className="product-price">
                            <span className="unit-amount">${product.price}</span>
                        </td>

                        <td className="product-quantity">
                            <div className="input-counter">
                                <span 
                                    className="minus-btn"
                                    onClick={()=>{handleSubtractQuantity(product.id)}}
                                >
                                    <i className="fas fa-minus"></i>
                                </span>
                                <input 
                                    type="number" 
                                    value={quantity} 
                                    min="1" 
                                    max={10} 
                                    readOnly={true}
                                    onChange={e => (e)}
                                />
                                <span 
                                    className="plus-btn"
                                    onClick={()=>{handleAddQuantity(product.id)}}
                                >
                                    <i className="fas fa-plus"></i>
                                </span>
                            </div>
                        </td>

                        <td className="product-subtotal">
                            <span className="subtotal-amount">${product.price * product.quantity}</span>

                            <a href="#">
                                <a
                                    className="remove"
                                    onClick={(e)=>{e.preventDefault();handleRemove(product.id)}}
                                >
                                    <i className="far fa-trash-alt"></i>
                                </a>
                            </a>
                        </td>
                    </tr>
                )
        
}

export default (CartProduct)
