import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app'
import "firebase/messaging"
import "firebase/functions"
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n'

import './stylo/assets/styles/animate.min.css'
import './stylo/assets/styles/bootstrap.min.css'
import './stylo/assets/styles/fontawesome.min.css'
import './stylo/assets/styles/responsive.css'
import './stylo/assets/styles/slick.css'
import './stylo/assets/styles/slick-theme.css'
import './stylo/assets/styles/style.css'
import { SettingsProvider } from './SettingsProvider';
import { OnlineShopProvider } from './OnlineShopProvider';


ReactDOM.render((
    <SettingsProvider>
    <OnlineShopProvider>
      <App />
    </OnlineShopProvider>
    </SettingsProvider>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
