import React, { Component, useEffect, useState } from 'react';
import Slider from "react-slick";
import { ProductType } from '../ProductPage';

const  ProductImage = ({product}: {product: ProductType}) =>  {
    const [nav1, setNav1] = useState<Slider |  undefined>(undefined)
    const [nav2, setNav2] = useState<Slider |  undefined>(undefined)



    const renderSliderMainImages = () => {
        return product.images.map(({id, image}) => {
            return (
                <div key={id}>
                    <div className="item">
                        <img src={image} alt="image" />
                    </div>
                </div>
            )
        })
    }

    const renderSliderSubImages = () => {
        return product.images.map(({id, image}) => {
            return (
                <div key={id}>
                    <div className="item">
                        <img src={image} alt="image" />
                    </div>
                </div>
            )
        })
    }

        return (
            <div className="col-lg-6 col-md-6">
                <div className="products-page-gallery">
                    <div className="product-page-gallery-main">
                       <div>
                            <Slider
                                asNavFor={nav2}
                                ref={slider => setNav1(slider!)}
                            >
                                {
                                    renderSliderMainImages()
                                }
                            </Slider>
                       </div>
                    </div>

                    <div className="product-page-gallery-preview">
                        <div>
                            <Slider
                                asNavFor={nav1}
                                ref={slider => setNav2(slider!)}
                                slidesToShow={5}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                arrows={false}
                                dots={false}
                            >
                                {
                                    renderSliderSubImages()
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
}


export default ProductImage;
