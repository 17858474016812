import React, { useState } from 'react'
import { Grid, makeStyles, TextField, Backdrop, CircularProgress, Theme, createStyles, Button, Typography, Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ContactDataType } from '../../Contact/contactUtils';
import { EventCategory } from '../../ga';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundImage: "url(/images/client-bg.jpg)",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            position: "relative",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            paddingBottom: "26px",
            zIndex: 1,
            '&:after': {
                backgroundColor: "#64BE43"
            }
        },
        input: {
            marginRight: "2%",
            float: "left",
            width: "49%",
            height: "45px",
            marginBottom: "15px",
            border: "1px solid #ffffff",
            fontSize: "14px",
            lineHeight: 1.42857143,
            padding: "6px 12px",
            borderRadius: "4px",
            transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
            transitionProperty: "border-color, box-shadow",
            transitionDuration: "0.15s, 0.15s",
            transitionTimingFunction: "ease-in-out, ease-in-out",
            transitionDelay: "0s, 0s"
        },
        button: {
            minWidth: "200px",
            transition: "0.3s",
            borderRadius: "2px",
            letterSpacing: "1.2px",
            fontSize: "16px",
            backgroundColor: "black",
            border: "1px solid #64BE43",
            color: "#ffffff",
            display: "inline-block",
            margin: "0 5px",
            padding: "12px 30px",
            textTransform: "uppercase",
            "&:hover": {
                backgroundColor: "transparent",
                color: "black"
            }
        },
        backdrop: {
            zIndex: 1400,
            color: '#64BE43',
        },
        sectionBg: {
            backgroundImage: 'url("/images/background/elma-isotank.jpg")',
            backgroundAttachment: "fixed",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            "&:after": {
                content: "''",
                backgroundColor: "#64BE43",
                opacity: 0.4,
                width: "100%",
                left: 0,
                height: "100%",
                position: "absolute",
                zIndex: -1,
            }
        },
        icons: {
            backgroundColor: "rgba(221,245,198, 0.9)",
            borderRadius: "20px",
            padding: "6px 6px 6px 6px",
            "&:before": {
                content: "''",
                filter: "blur(20px)",
                height: "100%",
                width: "100%"

            }

        },
        message: {
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: 1.6,
            fontStretch: "stretch",
            fontFamily: "'Open Sans', 'sans-serif'",
            color: "#464646",
            background: "transparent"


        }

    }));


type UserData= {
  givenName: string,
        name: string,
        email: string,
        phone: string,
        street: string,
        postal: string,
        city: string,
        country:string

}

type ContactType = {
    contactD?: ContactDataType,
}

const CartContact = ({ contactD, }: ContactType) => {
    const classes = useStyles()
    const [showSubmit, setShowSubmit] = useState(false)
    const [showDialog, setDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [contactData, setContactData] = useState<UserData>({
        givenName: '',
        name: "",
        email: "",
        phone: "",
        street: "",
        postal: '',
        city: "",
        country: ''
    })


    return (
        <>
            <Grid container alignItems="center" alignContent="center" justify="center"  >
                <Grid item xs={11} md={8}>
                        <Grid container wrap="nowrap" spacing={3} justify="center" alignContent="center" direction="column">
                            <Grid item>
                                <Grid spacing={3} justify="space-between" container>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-password-input"
                                            label="Vorname"
                                            placeholder="Vorname"
                                            type="text"
                                            autoComplete="given-name"
                                            variant="filled"
                                            value={contactData.givenName}
                                            onChange={(event) =>  setContactData({ ...contactData, givenName: event.target.value })}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-password-input"
                                            label="Nachname"
                                            placeholder="Nachname"
                                            type="text"
                                            autoComplete="family-name"
                                            variant="filled"
                                            value={contactData.name}
                                            onChange={(event) =>  setContactData({ ...contactData, name: event.target.value })}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-password-input"
                                            label="Email"
                                            placeholder="Email"
                                            type="email"
                                            autoComplete="email"
                                            variant="filled"
                                            value={contactData.email}
                                            onChange={(event) =>  setContactData({ ...contactData, email: event.target.value })}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-password-input"
                                            label="Straße"
                                            placeholder="Straße"
                                            type="text"
                                            autoComplete="street-address"
                                            variant="filled"
                                            value={contactData.street}
                                            onChange={(event) =>  setContactData({ ...contactData, street: event.target.value })}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-password-input"
                                            label="Postleizahl"
                                            placeholder="Postleizahl"
                                            type="postal-code"
                                            autoComplete="postal-code"
                                            variant="filled"
                                            value={contactData.postal}
                                            onChange={(event) =>  setContactData({ ...contactData, postal: event.target.value })}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-password-input"
                                            label="Stadt"
                                            placeholder="Stadt"
                                            type="postal-code"
                                            autoComplete="shipping locality"
                                            variant="filled"
                                            value={contactData.city}
                                            onChange={(event) =>  setContactData({ ...contactData, city: event.target.value })}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-password-input"
                                            label="Land"
                                            placeholder="Land"
                                            type="country"
                                            autoComplete="country"
                                            variant="filled"
                                            value={contactData.country}
                                            onChange={(event) =>  setContactData({ ...contactData, country: event.target.value })}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField
                                            style={{ width: "100%", backgroundColor: "white" }}
                                            id="filled-phone-input"
                                            label="Telefonnummer"
                                            placeholder="Telefonnummer"
                                            type="number"
                                            autoComplete="tel"
                                            variant="filled"
                                            value={contactData.phone}
                                            onChange={(event) =>  setContactData({ ...contactData, phone: event.target.value })}

                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default CartContact