import { ContactDataType } from "../Contact/contactUtils";

const APIKEY = "614413bd-7f73-46aa-821c-a6e71bfed471"
const URL = "https://www.elma-isotank.de/contact.php/"


function encodeQueryData(data: any) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}


export const sendMail = async(contactData :ContactDataType ) => {
  const url = URL+"?"+ encodeQueryData({...contactData, api_key: APIKEY})


  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'appilcation/json'
    },
  });
  
  const responseJson = await response.json()
  return response; // parses JSON response into native JavaScript objects
}


type SendEmaiHrefType = {
  subject: string,
  body: string,
  email: string
}

export const sendMailHref = ({subject, body, email}: SendEmaiHrefType) => {
    var mailToLink = "mailto:"+email+ "?body=" + encodeURIComponent(body) +"&subject="+encodeURIComponent(subject) ;
    window.open(mailToLink, '_blank')

}
