import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Grid } from '@material-ui/core';

 const ShippingSwitch = ({onSet}: {onSet: (isShipping: boolean) => void}) => {
  const [state, setState] = React.useState({
    isShipping: true,
    label: "Shipping"
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Grid component="label" container alignItems="center" spacing={1}>
         <Grid item>Abholen</Grid>
         <Grid item>
         <Switch
            checked={state.isShipping}
            onChange={handleChange}
            name="isShipping"
            color="primary"
          />
      </Grid>
      <Grid item>Versenden per Spedition</Grid>
    </Grid>
  );
}

export default ShippingSwitch
