import React, { useRef } from 'react'
import {  useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useWindowSize from '../../utils/useWindowSize';
import LargeHeaderDefault from './LargeHeader/LargeHeaderDefault';
import MobileHeaderDefault from './MobileHeader/MobileHeaderDefault';




const DefaultHeader = () => {
  const [width, height] = useWindowSize()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <div style={{height: isMobile? '64px': "128px"}}>
      <div>
      <LargeHeaderDefault/>
      <MobileHeaderDefault  />
      </div>
      </div>
  )
}


export default DefaultHeader