import React from 'react';
import OfferArea from './OfferArea';
import Products from './Products';
import Banner from './Banner';
import { getProducts } from '../../utils/getProducts';
import { ProductType } from '../ProductPage';

const ShopStyleThree = () => {
    const products: ProductType[] = getProducts()
    
    return (
        <React.Fragment>
            <Banner/>
            <OfferArea />
            <Products products={products}  />
        </React.Fragment>
    );
}

export default ShopStyleThree;
