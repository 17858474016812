import { ProductType } from "../E-CommerceSite/ProductPage";
import { Basic } from "./ProductData/Basic";
import { Drive } from "./ProductData/Drive";
import { Premium } from "./ProductData/Premium";

export const products: ProductType[] = [
    {
        id: 1,
       ...Basic
    },
    {
        id: 2,
      ...Premium
},
    {
        id: 3,
        ...Drive
},
    {
        id: 4,
        title: "Holzgestell",
        price: 245,
        image: require("./images/img4.jpg"),
        imageHover: require("./images/img-hover4.jpg"),
        quickView: require("./images/quick-view-img.jpg"),
        titleText: "",
        images: [
            {
                id: 1,
                image: require('./images/product-details/1.jpg')
            },
            {
                id: 2,
                image: require('./images/product-details/2.jpg')
            },
            {
                id: 3,
                image: require('./images/product-details/3.jpg')
            },
            {
                id: 4,
                image: require('./images/product-details/4.jpg')
            },
            {
                id: 5,
                image: require('./images/product-details/5.jpg')
            },
            {
                id: 6,
                image: require('./images/product-details/6.jpg')
            },
            {
                id: 7,
                image: require('./images/product-details/7.jpg')
            }
    ]
},
    {
        id: 5,
        title: "Befestigungssatz",
        price: 290,
        image: require("./images/img5.jpg"),
        imageHover: require("./images/img-hover5.jpg"),
        quickView: require("./images/quick-view-img.jpg"),
        titleText: "",
        images: [
            {
                id: 1,
                image: require('./images/product-details/1.jpg')
            },
            {
                id: 2,
                image: require('./images/product-details/2.jpg')
            },
            {
                id: 3,
                image: require('./images/product-details/3.jpg')
            },
            {
                id: 4,
                image: require('./images/product-details/4.jpg')
            },
            {
                id: 5,
                image: require('./images/product-details/5.jpg')
            },
            {
                id: 6,
                image: require('./images/product-details/6.jpg')
            },
            {
                id: 7,
                image: require('./images/product-details/7.jpg')
            }
    ]
},
]
