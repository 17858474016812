import { ContactType } from "./sendWhatsApp"

const URL = "fb-messenger://share?link="
const app_id = ""



export const sendFaceBook = ({title, text, data}: ContactType ) => {

  const string = title+ "\n" + text

  let url = URL+encodeURI(string)+  '&app_id='+ encodeURIComponent(app_id)

  window.open(url, "_blank");
}