export const Drive = {
  title: "Elma-Isotank Drive",
        price: 1645,
        image: require("../images/img3.jpg"),
        titleText: "",
        imageHover: require("../images/img-hover3.jpg"),
        quickView: ("../images/quick-view-img.jpg"),
        images: [
          {
              id: 1,
              image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
          },
          {
              id: 2,
              image: require('../elmaImages/price/Elma-Isotank-nur-Tank.jpg')
          },
          {
              id: 3,
              image: require('../elmaImages/price/elma-tank-offen.jpg')
          },
          {
              id: 4,
              image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
          },
          {
              id: 5,
              image: require('../elmaImages/price/Elma-Isotank-nur-Tank.jpg')
          },
          {
              id: 6,
              image: require('../elmaImages/price/elma-tank-offen.jpg')
          },
          {
              id: 7,
              image: require('../elmaImages/price/Elma-Isotank-Holzgestell-Tank.jpg')
          }
  ]
}