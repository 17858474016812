import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';




 const  HideOnScroll = (props: any) =>  {
  const { children, onHidden, direction } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger()


  useEffect(() => {
    if(onHidden) onHidden(trigger)

  },[trigger])

  return (
    <Slide appear={false} direction={direction? direction: 'down'} in={!trigger} unmountOnExit>
       {children}
    </Slide>
  );
}

export default HideOnScroll