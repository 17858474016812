import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import ContactFooter from './RootComponents/ContactFooter'
import { useLocation } from 'react-router-dom'
import HeaderDefault from './RootComponents/Headers/HeaderDefault'
import {initAnaltytics} from './ga'
import {initPixels} from './pixels'
import ReactGA from 'react-ga'
import ProductContent from './E-CommerceSite/ProductPage'
import CartContentPage from './E-CommerceSite/Cart/CartContentPage'
import Shop from './E-CommerceSite/Shop/Shop'
import ScrollToTop from './ScrollToTop'

const LazyDashboard = React.lazy(() => import('./Dashboard/Dashboard'))
const LazyProduct = React.lazy(() => import('./ProductItem/ProductItem'))
const LazyZubehör = React.lazy(() => import('./ProductItem/Zubehör'))
const LazyImpressum = React.lazy(() => import('./Bürokratie/Impressum'))
const LazyDatenschutz = React.lazy(() => import('./Bürokratie/Datenschutz'))
const LazyElma = React.lazy(() => import('./Elma-Isotank/Elma'))
const LazyFAQ = React.lazy(() => import('./FAQ/Faq'))
const LazyService = React.lazy(() => import('./Service/ServiceRouter'))
const LazyIsotankInfo = React.lazy(() => import('./IsoTankInfos/IsotankInfo'))
const LazyAnalytics = React.lazy(() => import('./RootComponents/Analytics/Analytics'))
const LazyProducts = React.lazy(() => import('./Products/Products'))

const Products = () => (
  <React.Suspense fallback={null}>
    <LazyProducts/>
  </React.Suspense>
)

const Dashboard =  () => (
  <React.Suspense fallback={null}>
    <LazyDashboard/>
  </React.Suspense>
)


const Analytics =  () => (
  <React.Suspense fallback={null}>
    <LazyAnalytics/>
  </React.Suspense>
)

const IsotankInfo =  () => (
  <React.Suspense fallback={null}>
    <LazyIsotankInfo/>
  </React.Suspense>
)

const Zubehör =  () => (
  <React.Suspense fallback={null}>
    <LazyZubehör/>
  </React.Suspense>
)

const Product =  () => (
  <React.Suspense fallback={null}>
    <LazyProduct/>
  </React.Suspense>
)

const Service =  () => (
  <React.Suspense fallback={null}>
    <LazyService/>
  </React.Suspense>
)

const FAQ =  () => (
  <React.Suspense fallback={null}>
    <LazyFAQ/>
  </React.Suspense>
)

const Impressum = () => (
  <React.Suspense fallback={null}>
  <LazyImpressum/>
</React.Suspense>
)

const Datenschutz = () => (
  <React.Suspense fallback={null}>
  <LazyDatenschutz/>
</React.Suspense>
)

const ElmaIsotank = () => (
  <React.Suspense fallback={null}>
  <LazyElma/>
</React.Suspense>
)

const EntranceRouter = () => {
  return (
      <Router>
         <ScrollToTop/>
         <HeaderDefault/>
        <Switch>
        <Route path='/admin' component={Analytics} />
      <Route  component={AppRouter} />
        </Switch>
        <ContactFooter/>
      </Router>
  )
}

const ServiceRouter = React.memo(({ }: { location: string }) => (
      <Switch>
      <Route path='/products/:id' component={ProductContent} />
      <Route  path='/products' component={Shop} />
      <Route path='/supplements' component={Zubehör} />
      <Redirect from='/info/:any' to='/info' />
       <Route path='/info' component={IsotankInfo} />
      <Redirect from='/about/:any' to='/about' />
       <Route path='/about' component={ElmaIsotank} />
      <Route path="/test" component={ProductContent}/>
      <Route path="/shop" component={Shop}/>
      <Redirect from='/cart/:any' to='/cart' />
      <Route path="/cart" component={CartContentPage}/>
       <Route path='/service' component={Service} />
       <Redirect from='/faq/:any' to='/faq' />
       <Route path='/faq' component={FAQ} />
      <Redirect from='/impressum/:any' to='/impressum' />
      <Route path='/impressum' component={Impressum} />
      <Redirect from='/terms/:any' to='/terms' />
      <Route path='/terms' component={Datenschutz} />
      <Redirect from='/:any' to='/' />
    <Route  path='/' component={Dashboard} />
      </Switch>
), (prev, next) => prev.location === next.location)





const AppRouter = () => {

  const [isAdmin, setAdmin] = useState(localStorage.getItem("admin") !== null ? true : false)
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname+location.search+location.hash
    ReactGA.pageview(path);
  },[location])

  useEffect(() => {
    function checkAdminStorage() {
      const admin = localStorage.getItem("admin") !== null ? true : false
        setAdmin(admin)
    }

    window.addEventListener('storage', checkAdminStorage)

    return () => {
      window.removeEventListener('storage', checkAdminStorage)
    }
  }, [])

  useEffect(() => {
    if(!isAdmin){
      initAnaltytics()
      initPixels()
    }
  },[isAdmin])
  
  return (  
    <ServiceRouter location={location.pathname} />
  )
}


export default EntranceRouter