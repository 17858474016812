import React from 'react';
import { useHistory } from 'react-router';

const OfferArea = () => {
    const history = useHistory()

        return (
            <section className="offer-area ptb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="offer-box">
                                <img src={require("../../images/offer-women.jpg")} alt="image" />

                                <div className="category">
                                    <h4>Pferdetränke</h4>
                                </div>

                                <div className="box-inner">
                                    <h3>ferdetränke</h3>

                                    <ul>
                                        <li>
                                            <a href="/" onClick= {
                                                (e) => {
                                                    e.preventDefault()
                                                    history.push(`/products/${1}`)
                                                }
                                            }>
                                                <a>Elma-Isotank Basic</a>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/" 
                                            onClick= {
                                                (e) => {
                                                    e.preventDefault()
                                                    history.push(`/products/${2}`)
                                                }
                                            }>
                                            <a>Elma-Isotank Premium</a>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/" 
                                            onClick= {
                                                (e) => {
                                                    e.preventDefault()
                                                    history.push(`/products/${3}`)
                                                }
                                            }>
                                            <a>Elma-Isotank Drive</a>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="offer-box">
                                <img src={require("../../images/1.jpg")} alt="image" />

                                <div className="category">
                                    <h4>Zubehör</h4>
                                </div>

                                <div className="box-inner">
                                    <h3>Zubehör</h3>

                                    <ul>
                                        <li>
                                            <a href="#"onClick= {
                                                (e) => {
                                                    e.preventDefault()
                                                    history.push(`/products/${4}`)
                                                }
                                            }>
                                                <a>Holzgestell</a>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick= {
                                                (e) => {
                                                    e.preventDefault()
                                                    history.push(`/products/${5}`)
                                                }
                                            }>
                                                <a>Befestigugnssatz</a>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default OfferArea;
